document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {
        if ($('.mobile-menu .menu > li.menu-item-has-children > a').length) {
            $('.mobile-menu .menu > li.menu-item-has-children > a').append('<i class="fa fa-angle-down fa-lg"></i>');
        }
        $('.mobile-menu-button').on('click', (function () {
            $(this).toggleClass('open');
            $('body').toggleClass('mobile-menu-open');
            $('.mobile-menu').toggleClass('open');
        }));
        $('.mobile-menu .menu > li.menu-item-has-children > a > i').on('click', (function (e) {
                e.preventDefault();
                $(this).parent().parent().toggleClass('open');
                $(this).parent().parent().siblings().removeClass('open');
        }));
        $('.mobile-menu-overlay').on('click', function (e) {
            $('.mobile-menu-button').toggleClass('open');
            $('body').toggleClass('mobile-menu-open');
            $('.mobile-menu').toggleClass('open');
        })
    });
})(jQuery);
